import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { FiBell } from 'react-icons/fi';
import Gravatar from 'react-gravatar';
import { isAi } from 'utils/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
//mui
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import Speaking from '@material-ui/icons/SettingsVoice';
import Writing from '@material-ui/icons/Create';
import Listening from '@material-ui/icons/Headset';
import Reading from '@material-ui/icons/ImportContacts';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Avatar, Button, Container, Box, Menu, MenuItem, Badge, AppBar, Hidden, useMediaQuery, IconButton, Toolbar, Drawer, Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
//components
import HelpIcon from 'components/Icons/gen/Help';
import MockTestIcon from 'components/Icons/gen/MockTest';
import BookIcon from 'components/Icons/gen/Book';
import ArrowDownIcon from 'components/Icons/gen/Down';
import {
  logoutAction,
  fetchAllUnreadNotificationsAction,
  showProgress
} from 'core/lib/adapters/redux/actions';
import logo from '../assets/images/logo.svg';
import NotificationsList from './Notification';
import menuMobile from "../assets/icons/mobileMenu.svg"
import home from "../assets/icons/home.svg"
import CommanMenuData from './Common/CommanMenuData';

// >>HEADER 
const useMobileStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  headerLogo: {
    width: '150p',
  },
  title: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  drawer: { width: '100%' },
  drawerWidth: {
    '& .MuiDrawer-paper': { width: '100%' },
  },
  profileMainSec: {
    background: '#e7eef4',
    padding: theme.spacing(2),
    borderTop: '1px solid #e3e3e3',
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: 10,
  },
  gravatarImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  userEmail: {
    // color: 'white',
    fontSize: 13,
  },
  userProfile: {
    // color: 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileProgressReportTag: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    marginTop: '12px',
    padding: '6px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progressFont: {
    fontSize: '14px',
    fontWeight: '600',
  },
  nested: {
    marginLeft: theme.spacing(3),
  },
  sidebarLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  listItemText: {
    color: '#808080',
    fontWeight: 600,
  },
  listItemTextActive: {
    color: theme.palette.primary.main,
  },
}));

const useDesktopStyles = makeStyles((theme) => ({
  animatedText: {
    animation: '$colorChange 3s infinite',
    textTransform: 'initial',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 500,
  },
  animatedIcon: {
    animation: '$colorChange 3s infinite',
  },
  '@keyframes colorChange': {
    '0%': { color: '#09121F' },
    '32%': { color: '#09121F' },
    '33%': { color: '#E2B207' },
    '65%': { color: '#E2B207' },
    '66%': { color: '#EC3C3C' },
    '99%': { color: '#EC3C3C' },
    '100%': { color: '#09121F' },
  },
  deshboardLogoSpacing: {
    paddingLeft: theme.spacing(6)
  },
  gridDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    // marginLeft: theme.spacing(2),
  },
  avatarMobile: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  gravatarImage: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  upgradeButton: {
    backgroundColor: '#FF3C3C',
    marginRight: theme.spacing(2),
    height: theme.spacing(4),
    color: '#fff',
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: '#FF3C3C',
    },
  },
  appbar: {
    backgroundColor: '#fff',
    // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
    padding: '6px 0px',
    borderBottom: '1px solid #eee',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    // marginLeft: theme.spacing(4),
  },
  headerMenuBox: {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
    marginLeft: theme.spacing(4.5),
    [theme.breakpoints.down('lg')]: {
      gap: '30px'
    }
  },
  headerTag: {
    borderRadius: '50px 0 0 50px',
    backgroundColor: '#ceffc8',
    color: '#24953e',
    fontSize: '12px',
    lineHeight: '15px',
    padding: '1px 8px',
    // marginLeft: '-20px',
    fontWeight: 600,
  },
  navigationButton: {
    background: 'none',
    color: '#000',
    textTransform: 'initial',
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 500,
  },
}));
const useProductAppBarStyles = makeStyles((theme) => ({
  megaMenu: {
    width: '100%',
    marginTop: 64,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    maxWidth: '100%',
    position: 'initial',
    borderRadius: 'initial',
  },
  megaMenuTitle: {
    fontWeight: 650,
    marginBottom: theme.spacing(2),
  },
  megaMenuItemContainer: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  megaMenuItem: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  megaMenuContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    outline: 'none',
  },
  logoRef: {
    height: 45,
  },
  aiSore: {
    fontWeight: 600,
    fontSize: '11px',
  },
}));

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
const MobileAppBar = ({ navigations, auth, t, setProgessClick }) => {
  const [showNotifList, setShowNotifList] = useState(false);
  const classes = useMobileStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const showProgressData = useSelector((state) => state?.dashboard?.showProgress);


  const { unreadNotifications } = useSelector(
    (state) => ({
      unreadNotifications: state.notification.unreadNotifications,
      // showProgress: state.showProgress
    }),
    shallowEqual,
  );
  const [openPractice, setOpenPractice] = useState(true);
  const [openSupport, setOpenSupport] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    const updatedOpenItems = [...openItems];
    const currentIndex = updatedOpenItems.indexOf(index);
    if (currentIndex === -1) {
      updatedOpenItems.push(index);
    } else {
      updatedOpenItems.splice(currentIndex, 1);
    }
    setOpenItems(updatedOpenItems);
  };
  const toggleDrawer = (event, payload) => {
   setDrawerOpen(payload)
  };



  const handleClick = (title) => {
    if (title === t('practice')) {
      setOpenPractice(!openPractice);
    } else if (title === 'Support') setOpenSupport(!openSupport);
  };

  useOutsideClick(notificationRef, () => {
    if (showNotifList) setShowNotifList(false);
  });

  const skills = ['Speaking', 'Writing', 'Reading', 'Listening'];
  const isActiveSubRoute = (route) => router.asPath === route;

  const generateSkillIcon = (type) => {
    switch (type) {
      case 'Speaking':
        return (
          <Speaking
            style={isActiveSubRoute(`/practice/${type}`) ? { color: '#000' } : {}}
          />
        );
      case 'Writing':
        return (
          <Writing
            style={isActiveSubRoute(`/practice/${type}`) ? { color: '#000' } : {}}
          />
        );
      case 'Reading':
        return (
          <Reading
            style={isActiveSubRoute(`/practice/${type}`) ? { color: '#000' } : {}}
          />
        );
      case 'Listening':
        return (
          <Listening
            style={isActiveSubRoute(`/practice/${type}`) ? { color: '#000' } : {}}
          />
        );
      default:
        return (
          <Speaking
            style={isActiveSubRoute(`/practice/${type}`) ? { color: '#000' } : {}}
          />
        );
    }
  };
  useEffect(() => {
    let isProgress = false
    dispatch(showProgress(isProgress));

  }, [])
  useEffect(() => {
    dispatch(fetchAllUnreadNotificationsAction());
    // dispatch(fetchAllNotificationsAction());
  }, []);
  const isActiveRoute = (route) =>
    router.asPath === route || (route && router.asPath.startsWith(route));
  // const CustomLink = ({ type, children, ...props }) => {
  //   const classes = useStyles();
  //   return type === 'Link' ? (
  //     <Link href={props.href} key={props.key}>
  //       <a className={classes.sidebarLink}>{children}</a>
  //     </Link>
  //   ) : (
  //     <div key={props.key}>{children}</div>
  //   );
  // };
  const list = (e) => (

    <div className={classes.drawer} role="presentation">
      <Box
        style={{
          margin: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '4px',
        }}>

        <Link href="/dashboard" >
          <a id="app-logo">
            <img
              src={logo}
              // mobile screen
              alt="Benchmark Education Solutions"
              // className={classes.headerLogo}
              width="100"
              onClick={() => dispatch(showProgress(false))}
            />
          </a>
        </Link>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Button
            variant="contained"
            style={{
              height: '24px',
              lineHeight: '12px',
              fontSize: '12px',
              padding: '3px 7px',
              fontWeight: '400',
            }}
            onClick={() => { router.push('/subscribe'); }}
          >
            {t('upgradePackage')}
          </Button>
          <Box>
            {auth.isAuth && (
              <div style={{ position: 'relative' }}>
                {unreadNotifications?.data?.length !== 0 && (
                  <div
                    style={{
                      backgroundColor: '#FF3C3C',
                      position: 'absolute',
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      right: 13,
                      top: 11,
                      zIndex: 1,
                    }}
                  />
                )}
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setShowNotifList(!showNotifList);
                    if (!showNotifList)
                      dispatch(fetchAllUnreadNotificationsAction());
                  }}>
                  <Badge color="error" variant="dot" invisible>
                    <FiBell color="#000" size="18" />
                  </Badge>
                </IconButton>
                <div ref={notificationRef}>
                  <NotificationsList
                    showList={showNotifList}
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </div>
            )}
          </Box>
          <CloseIcon
            onClick={(e) => toggleDrawer(e, false)}
            style={{ fontSize: '18px' }}
          />
        </Box>
      </Box>
      <div className={classes.profileMainSec}>
        <Box className={classes.profileSection}>
          {auth.isAuth ? (
            <>
              {auth.user.avatar ? (
                <Avatar className={classes.avatar} src={auth.user.avatar} />
              ) : (
                <Avatar className={classes.avatar}>
                  <Gravatar
                    email={auth.user.email}
                    className={classes.gravatarImage}
                  />
                </Avatar>
              )}
              <Grid container direction="column">
                <Typography className={classes.userProfile} variant="subtitle1">
                  {auth.user.name}
                </Typography>
                <Typography className={classes.userEmail} variant="subtitle2">
                  {auth.user.email}
                </Typography>
              </Grid>
              <IconButton
                color="inherit"
                style={{ backgroundColor: '#fff', marginLeft: '4px' }}
                onClick={() => {
                  router.push('/user/[type]', '/user/profile');
                }}>
                <EditOutlinedIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </>
          ) : (
            <Button
              onClick={() => {
                router.push('/auth/login');
              }}
              fullWidth
              color="secondary"
              variant="contained">
              {t('loginOrSignUp')}
            </Button>
          )}
        </Box>
        <Link href={"/dashboard"}>
          <Box
            className={classes.mobileProgressReportTag}
            onClick={(e) => {
              dispatch(showProgress(true));
              toggleDrawer(e, false);
            }}
          >
            <Typography className={classes.progressFont}>
              {' '}
              Your Progess Report
            </Typography>
            <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
          </Box>
        </Link>
      </div>
      <CommanMenuData t={t} toggleDrawer={toggleDrawer}/>
    </div>
  );
  return (
    <>
      {/* <AppBar position="static"> */}
      <Toolbar className={classes.root}>
        <Link href="/dashboard">
          <a id="app-logo">
            <img
              src={logo}
              // mobile screen
              alt="Benchmark Education Solutions"
              // className={classes.headerLogo}
              width="100"
            />
          </a>
        </Link>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <IconButton color="primary" onClick={(e) => toggleDrawer(e, true)}>
            <img src={menuMobile} />
          </IconButton>
          {/* <Typography variant="h6" display="inline" className={classes.title}>
            {title}333
          </Typography> */}
          {auth.isAuth ? (
            <>
              {auth.user.avatar ? (
                <Avatar
                  className={classes.avatarMobile}
                  src={auth.user.avatar}
                />
              ) : (
                <Avatar className={classes.avatarMobile}>
                  <Gravatar
                    email={auth.user.email}
                    className={classes.gravatarImage}
                  />
                </Avatar>
              )}
              {/* <Grid container direction="column">
              <Typography
                className={classes.userProfile}
                variant="subtitle1"
                onClick={() => {
                  router.push('/user/[type]', '/user/profile');
                }}>
                {auth.user.name}
              </Typography>
              <Typography className={classes.userEmail} variant="subtitle2">
                {auth.user.email}
              </Typography>
            </Grid> */}
            </>
          ) : (
            <Button
              onClick={() => {
                router.push('/auth/login');
              }}
              fullWidth
              color="secondary"
              variant="contained">
              {t('loginOrSignUp')}
            </Button>
          )}
        </Box>
        {/* {auth.isAuth && (
          <div style={{ position: 'relative' }}>
            {unreadNotifications?.data?.length !== 0 && (
              <div
                style={{
                  backgroundColor: '#FF3C3C',
                  position: 'absolute',
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  right: 13,
                  top: 11,
                  zIndex: 1,
                }}
              />
            )}
            <IconButton
              color="inherit"
              onClick={() => {
                setShowNotifList(!showNotifList);
                if (!showNotifList)
                  dispatch(fetchAllUnreadNotificationsAction());
              }}>
              <Badge color="error" variant="dot" invisible>
                <FiBell color="#000" size="19.5" />
              </Badge>
            </IconButton>
            <div ref={notificationRef}>
              <NotificationsList showList={showNotifList} />
            </div>
          </div>
        )} */}
      </Toolbar>
      {/* </AppBar> */}
      <Drawer
        className={classes.drawerWidth}
        anchor="right"
        open={isDrawerOpen}
        onClose={(e) => toggleDrawer(e, false)}>
        {/* setProgessClick={setProgessClick} */}
        {list()}
      </Drawer>
    </>
  );
};

const DesktopAppBar = ({ iconColor, t, navigations, auth, setProgessClick }) => {
  const classes = useDesktopStyles();
  // notifications Redux state
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const { unreadNotifications } = useSelector(
    (state) => ({
      unreadNotifications: state.notification.unreadNotifications,
    }),
    shallowEqual,
  );

  const [showNotifList, setShowNotifList] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const router = useRouter();
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDashboard = () => {
    router.push('/dashboard');
    handleMenuClose();
  };
  const handleProfile = () => {
    router.push('/user/profile');
    handleMenuClose();
  };

  const handleLogout = () => {
    handleMenuClose();
    dispatch(logoutAction());
    // router.push('/auth/login');
    window.location.href = '/auth/login';
  };

  const menuId = 'primary-search-account-menu';
  const menu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleDashboard}>Dashboard</MenuItem>
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  useOutsideClick(notificationRef, () => {
    if (showNotifList) setShowNotifList(false);
  });

  useEffect(() => {
    dispatch(fetchAllUnreadNotificationsAction());
    // dispatch(fetchAllNotificationsAction());
  }, []);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appbar} elevation={0}>
        {/* <Toolbar> */}
        <Grid container>
          <Grid
            className={`${classes.gridDisplay} ${classes.deshboardLogoSpacing}`}
            item
            xs={2}>
            <Box>
              <Link href="/dashboard">
                <a className={classes.logoRef}>
                  <img src={logo} alt="logo" />
                  {/* //desktop screen */}
                </a>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box style={{ paddingRight: ' 16px' }}>
              {/* <Typography variant="h1" className={`${classes.animatedText} colorChange`}>
                  Hello World
                </Typography> */}
              <Box
                style={{
                  display: 'flex',
                  gap: '24px',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div className={classes.navigation}>
                  <Divider orientation="vertical" style={{ height: '20px' }} />
                  <Box className={classes.headerMenuBox}>
                    {navigations.map((nav, index) => (
                      <Box className={classes.gridDisplay}>
                        <Button
                          className={classes.navigationButton}
                          // className={
                          //   nav?.tag
                          //     ? classes.animatedText
                          //     : classes.navigationButton
                          // }
                          style={{ color: nav?.tag ? iconColor : '#000' }}
                          startIcon={nav?.tag ? nav.Icon : ''}
                          key={index}
                          endIcon={nav.endIcon}
                          onClick={(event) => nav.onClick(event)}
                          onMouseEnter={(event) => nav.onMouseEnter(event)}>
                          {nav.title}
                        </Button>
                        {nav?.tag && (
                          <Typography className={classes.headerTag}>
                            {' '}
                            {nav?.tag}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </div>
                <Box
                  style={{
                    display: 'flex',
                    gap: '12px',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}>
                  {/* <Button
                      className={classes.upgradeButton}
                      variant="contained"
                      onClick={() => router.push('/subscribe')}>
                      {t('upgradePackage')}
                    </Button> */}
                  {/* <SearchIcon style={{color:"#808080"}}  />
                    <Typography variant='caption' style={{ color: "#000" , minWidth:"150px"}} > Search Anything... </Typography>
                    <Divider orientation="vertical" style={{ height: "20px" }} /> */}
                  {auth.isAuth && (
                    <div style={{ position: 'relative' }}>
                      {unreadNotifications?.data?.length !== 0 && (
                        <div
                          style={{
                            backgroundColor: '#FF3C3C',
                            position: 'absolute',
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            right: 13,
                            top: 11,
                            zIndex: 1,
                          }}
                        />
                      )}
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          setShowNotifList(!showNotifList);
                          if (!showNotifList)
                            dispatch(fetchAllUnreadNotificationsAction());
                        }}>
                        <Badge color="error" variant="dot" invisible>
                          <FiBell color="#000" size="18.5" />
                        </Badge>
                      </IconButton>
                      <div ref={notificationRef}>
                        <NotificationsList showList={showNotifList} />
                      </div>
                    </div>
                  )}
                  <Box style={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" style={{ color: "#09121F", fontWeight: 600, fontSize: '18px', lineHeight: '18px' }}>
                      {auth?.user?.name}
                    </Typography>
                    {/* <Typography variant="subtitle2" style={{ color: "#808080", fontSize: '13px' }}>
                      {auth?.user?.email}
                    </Typography> */}
                  </Box>
                  {auth.isAuth ? (
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      size="medium">
                      {auth.user.avatar ? (
                        <Avatar
                          className={classes.avatar}
                          src={auth.user.avatar}
                        />
                      ) : (
                        <Avatar className={classes.avatar}>
                          <Gravatar
                            email={auth.user.email}
                            className={classes.gravatarImage}
                          />
                        </Avatar>
                      )}
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => router.push('/auth/login')}
                      color="primary">
                      {t('login')}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* </Toolbar> */}
      </AppBar>
      {menu}
    </div>
  );
};
const ProductAppBar = ({
  setProgessClick,
  t,
  mobileNav,
  showMobileAppBar,
  auth,
  title,
}) => {
  const [megaAnchor, setMegaAnchor] = useState(null);
  const [openItems, setOpenItems] = useState([]);
  const [iconColor, setIconColor] = useState('#09121F');
  const router = useRouter();
  const openMegaMenu = (target) => setMegaAnchor(target);
  const closeMegaMenu = () => setMegaAnchor(null);
  const categorizedQuestions = useSelector(
    (state) => state.questions.categorized,
  );
  const classes = useProductAppBarStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIconColor((prevColor) => {
        if (prevColor === '#09121F') {
          return '#E2B207';
        } else if (prevColor === '#E2B207') {
          return '#EC3C3C';
        } else {
          return '#09121F';
        }
      });
    }, 999);
    return () => clearInterval(intervalId);
  }, []);

  const navigations = [
    {
      title: t('home'),
      Icon: <img src={home} width={20} height={20} style={{ filter: 'brightness(0)' }} />,
      onClick: () => router.push('/dashboard'),
      onMouseEnter: () => { },
    },
    {
      title: t('mockTests'),
      // Icon: <img src={HeaderMockTest} width={20} height={20} style={{ filter: 'brightness(0)' }} />,
      Icon: <MockTestIcon width={18} height={18} color={iconColor} />,
      onClick: () => router.push('/mock'),
      onMouseEnter: () => { },
      tag: 'FREE',
    },
    {
      title: t('practiceQuestion'),
      Icon: <HelpIcon width={20} height={20} color="#000" />,
      endIcon: <ArrowDownIcon width={15} height={15} color="#000" />,
      onClick: (event) => {
        if (!isSm) openMegaMenu(event.currentTarget);
        else router.push('/practice');
      },
      onMouseEnter: (event) => openMegaMenu(event.currentTarget),
    },
    // {
    //   title: t('onlineClasses'),
    //   Icon: <OnlineClassIcon width={24} height={24} color="#000" />,
    //   onClick: () => router.push('/session'),
    //   onMouseEnter: () => {},
    // },
    {
      title: t('pteCourse'),
      Icon: <BookIcon width={20} height={20} color="#000" />,
      onClick: () => router.push('/pte-course'),
      onMouseEnter: () => { },
    },
    // {
    //   title: t('support'),
    //   Icon: <HelpIcon width={24} height={24} color="#808080" />,
    //   onClick: () => router.push('/support/ticket'),
    //   onMouseEnter: () => {},
    // },
  ];
  return (
    <>
      {mobileNav && showMobileAppBar && (
        <Hidden lgUp>
          <MobileAppBar
            t={t}
            navigations={navigations}
            auth={auth}
            title={title}
            setProgessClick={setProgessClick}
          />
        </Hidden>
      )}

      <Hidden mdDown>
        <DesktopAppBar iconColor={iconColor} t={t} navigations={navigations} auth={auth} />
      </Hidden>
      <Menu
        PopoverClasses={{ paper: classes.megaMenu }}
        id="customized-menu"
        anchorEl={megaAnchor}
        open={Boolean(megaAnchor)}
        onClose={closeMegaMenu}
        onMouseLeave={closeMegaMenu}>
        <Container
          maxWidth="lg"
          className={classes.megaMenuContainer}
          onMouseLeave={closeMegaMenu}>
          <Grid container>
            {Object.keys(categorizedQuestions).map((category) => (
              <Grid item xs={3} direction="column" container key={category}>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.megaMenuTitle}>
                  {category}
                </Typography>
                {Object.keys(categorizedQuestions[category]).map(
                  (questionType) => (
                    <Typography
                      key={questionType}
                      variant="body1"
                      color="primary"
                      className={classes.megaMenuItemContainer}>
                      <Link href={`/practice/${category}/${questionType}`}>
                        <a
                          className={classes.megaMenuItem}
                          onClick={closeMegaMenu}
                          role="presentation">
                          {categorizedQuestions[category][questionType]}{' '}
                          {isAi(questionType) && (
                            <span className={classes.aiSore}>
                              ({t('aiScore')})
                            </span>
                          )}
                        </a>
                      </Link>
                    </Typography>
                  ),
                )}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Menu>
    </>
  );
};

ProductAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  mobileNav: PropTypes.bool,
  showMobileAppBar: PropTypes.bool.isRequired,
  auth: PropTypes.shape().isRequired,
};
ProductAppBar.defaultProps = {
  mobileNav: true,
};
DesktopAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  navigations: PropTypes.array.isRequired,
  auth: PropTypes.shape().isRequired,
};

MobileAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  navigations: PropTypes.array.isRequired,
  auth: PropTypes.shape().isRequired,
};

export default ProductAppBar;
